<template>
  <div>
    <CompanyInfo
      :images="images"
      :companyBrandColour="companyBrandColour"
      :companyLogo="companyLogo"
    >
      <template #companyName>Pfizer</template>
      <template #date>2000 - 2008</template>
      <template #content>
        <ul class="dashed">
          <h4>Pfizer Cardiology</h4>
          <li>We supplied moderation for a series of Global Cardiology Meetings.</li>
          <li>
            Our brief was to encourage delegates onto the booth, engage their interest and
            educate by reaffirming key messaging surrounding Pfizer’s portfolio of
            cardiology products.
          </li>
          <h4>Pfizer Oncology</h4>
          <li>
            We supplied the script, direction and cast for a series of re-enactment
            scenarios that were presented to Oncology surgeons.
          </li>
          <li>
            The objective was to present the patients point of view raising concerns prior
            to and during a patient trial for a new cancer drug. This enabled the
            healthcare professionals to realise and understand the emotional impact a
            patient goes through during their treatment.
          </li>
          <p style="margin-top: 5px; margin-bottom: 5px">
            The first enactment was played out over a period of 3 years between a
            physician and patient.
          </p>
          <p style="margin-top: 0px; margin-bottom: 3px">
            The second enactment was presented as a monologue over a period of time direct
            to the healthcare professionals where a moderated question and answer session
            followed.
          </p>
        </ul>
      </template>
    </CompanyInfo>
  </div>
</template>

<script>
import CompanyInfo from "@/components/Portfolio/CompanyInfo.vue";
export default {
  components: { CompanyInfo },
    name: 'PfizerItem',
  data() {
    return {
      companyBrandColour: "#4B9FD9",
      companyLogo: require("@/assets/images/portfolio/pfizer-logo.svg"),
      images: [
        {
          id: 0,
          responsiveImages: require("@/assets/images/portfolio/pfizer/1.jpg"),
          position: { x: 50, y: 25 },
          pictureDetails:
            "PAN AMERICAN LEAGUE OF ASSOCIATIONS OF RHEUMATOLOGY (2002) ARUBA",
        },
        {
          id: 1,
          responsiveImages: require("@/assets/images/portfolio/pfizer/2.jpg"),
          position: { x: 50, y: 25 },
          pictureDetails:
            "PAN AMERICAN LEAGUE OF ASSOCIATIONS OF RHEUMATOLOGY (2002) ARUBA",
        },
        {
          id: 2,
          responsiveImages: require("@/assets/images/portfolio/pfizer/3.jpg"),
          position: { x: 50, y: 25 },
          pictureDetails: "THE INTERACTIVE PATIENT (2003) NEW YORK",
        },
        {
          id: 3,
          responsiveImages: require("@/assets/images/portfolio/pfizer/4.jpg"),
          position: { x: 50, y: 25 },
          pictureDetails: "EUROPEAN SOCIETY OF CARDIOLOGY (2002) STOCKHOLM",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
#companySummary > ul > h4:first-child {
  margin-top: 0px;
}
</style>
